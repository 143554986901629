import { Controller } from "@hotwired/stimulus"
import YouTubePlayer from 'youtube-player';

export default class extends Controller {
  static targets = [ "video", "youtube" ]
  static values = {
    videoId: String
  }

  connect() {
    this.player = YouTubePlayer(this.youtubeTarget);
    this.player.loadVideoById(this.videoIdValue);
    this.hideModal();
  }

  toggleModal = () => {
    this.videoTarget.classList.toggle("is-hidden");

    if (this.videoTarget.classList.contains("is-hidden")) {
      this.player.stopVideo();
    } else {
      this.player.playVideo();
    }
  }

  hideModal = () => {
    this.videoTarget.classList.add("is-hidden");
    this.player.stopVideo();
  }
}
